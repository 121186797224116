import React from 'react';
import LocationButton from '../components/LocationButton';
import { Link } from 'gatsby';
import Grid from '../components/Grid';
import Layout from '../layouts/layout';

const OrderOnline = () => (
	<Layout title="Order Online">
		<div>
			<h1>Order Online</h1>

			<p>
				If you are ordering for an event,{' '}
				<Link to="/catering">
					click here to see what catering services are available
				</Link>
			</p>

			<section>
				<h2>Pickup</h2>

				<p>
					For curbside pickup, call us at{' '}
					<a href="tel:+17602957993">
						<span className="sr-only">
							Call our downtown vista location at:{' '}
						</span>
						(760) 295-7993
					</a>{' '}
					when you arrive
				</p>

				<Grid>
					<LocationButton
						className="gray-button inline-button"
						href="http://toasttab.com/when-pigs-fly-bbq-main-street"
					>
						Click for pickup in Downtown Vista
					</LocationButton>
				</Grid>
			</section>

			<hr />

			<section>
				<h2>Delivery</h2>

				<Grid>
					<LocationButton
						className="gray-button inline-button"
						href="https://www.doordash.com/store/when-pigs-fly-bbq-vista-86223/"
					>
						Click for delivery with Doordash - Vista
					</LocationButton>
				</Grid>
			</section>
		</div>
	</Layout>
);

export default OrderOnline;
